class SingleProduct {
	constructor(page) {
		this.page = page
		this.featImage = page.querySelector(".wc-single-product__featured-image")
		this.galleryImages = page.querySelectorAll(".wc-single-product__gallery-image")
		this.tabListItems = page.querySelectorAll(".wc-single-product__tabs-list-item-link")
		this.tabs = page.querySelectorAll(".wc-single-product__tab")
		this.variations = page.querySelectorAll(".wc-single-product__variation")
		this.variationsForm = page.querySelector(".variations_form")
		this.events()
	}

	events() {
		this.galleryImages.forEach(image => {
			image.addEventListener("click", () => this.setFeaturedImage(image))
		})

		this.tabListItems.forEach(item => {
			item.addEventListener("click", e => this.selectTab(e, item))
		})

		//Set first tab item to active
		this.tabListItems[0]?.classList.add("wc-single-product__tabs-list-item-link--active")
		this.tabs[0]?.classList.add("wc-single-product__tab--active")

		//Handle variations
		this.variations.forEach(variation => {
			const select = variation.querySelector("select")

			if (select) {
				select.addEventListener("change", e => this.selectVariation(e))
			}
		})

		//Test mutations
		var observer = new MutationObserver(mutations => {
			mutations.forEach(mutation => {
				if (mutation.type === "attributes") {
					this.galleryImages.forEach(image => {
						if (image.dataset.image == mutation.target.getAttribute("current-image")) {
							this.setFeaturedImage(image)
						}
					})
				}
			})
		})

		observer.observe(this.variationsForm, {
			attributes: true, //configure it to listen to attribute changes
		})
	}

	selectVariation(e) {
		const imageId = this.variationsForm.getAttribute("current-image")
		console.log(imageId)

		// this.galleryImages.forEach(image => {
		// 	if (image.dataset.image == imageId) {
		// 		this.setFeaturedImage(image)
		// 	}
		// })
	}

	setFeaturedImage(image) {
		this.featImage?.setAttribute("src", image.src)
	}

	selectTab(e, item) {
		e.preventDefault()

		this.tabs.forEach(tab => {
			tab.classList.toggle("wc-single-product__tab--active", tab.dataset.tab == item.dataset.target)
		})

		this.tabListItems.forEach(tabListItem => {
			tabListItem.classList.toggle("wc-single-product__tabs-list-item-link--active", item == tabListItem)
		})
	}
}

export default SingleProduct
