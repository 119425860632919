class Hero {
	constructor(block) {
		this.block = block;
		this.caption = block.querySelector('.hero__caption');
		this.setParallax();
	}

	setParallax() {
		window.addEventListener('scroll', () => {
			const offset = window.scrollY;
			this.block.style.backgroundPositionY = `${offset * 0.5}px`;

			if (this.caption) {
				const captionOffset = offset * 0.15;
				this.caption.style.transform = `translateY(${captionOffset}px)`;
			}
		});
	}
}

export default Hero;
