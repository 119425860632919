import $ from "jquery"

class Header {
	constructor(header) {
		this.header = header
		this.headerLogo = header.querySelector(".site-header__logo-image")
		this.openMobileMenuButton = header.querySelector(".site-header__burger")
		this.mobileMenu = document.querySelector(".mobile-menu")
		this.closeMobileMenuButton = this.mobileMenu.querySelector(".mobile-menu__close")
		this.mobileMenuItems = this.mobileMenu.querySelectorAll(".mobile-menu__item")
		this.events()
	}

	events() {
		this.openMobileMenuButton.addEventListener("click", () => this.openMobileMenu())
		this.closeMobileMenuButton.addEventListener("click", () => this.closeMobileMenu())

		this.mobileMenuItems.forEach(item => {
			const button = item.querySelector(".mobile-menu__dropdown-button")
			const sublist = item.querySelector(".mobile-menu__sublist")

			if (button && sublist) {
				button.addEventListener("click", () => {
					const isActive = item.classList.contains("mobile-menu__item--active");

					if (isActive) {
						// If the clicked item is already active, close it
						item.classList.remove("mobile-menu__item--active");
						$(sublist).slideUp();
					} else {
						// Close all other dropdowns
						this.closeAllDropdowns();

						// Then open the clicked dropdown
						item.classList.add("mobile-menu__item--active");
						$(sublist).slideDown(() => {
							this.scrollIntoView(item);
						});
					}
				})
			}
		})

		window.addEventListener("scroll", () => this.handleScroll())
	}

	openMobileMenu() {
		if (this.mobileMenu) {
			this.mobileMenu.classList.add("mobile-menu--active")
			document.body.classList.add("mobile-menu--open"); // Prevent scrolling
		}
	}

	closeMobileMenu() {
		this.mobileMenu.classList.remove("mobile-menu--active")
		document.body.classList.remove("mobile-menu--open"); // Allow scrolling
	}

	handleScroll() {
		if (window.scrollY > 0) {
			this.header.classList.add("site-header--scrolled")
		} else {
			this.header.classList.remove("site-header--scrolled")
		}
	}

	scrollIntoView(element) {
		const rect = element.getBoundingClientRect();
		const isVisible = (
			rect.top >= 0 &&
			rect.left >= 0 &&
			rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			rect.right <= (window.innerWidth || document.documentElement.clientWidth)
		);

		if (!isVisible) {
			element.scrollIntoView({ behavior: "smooth", block: "center" });
		}
	}

	closeAllDropdowns() {
		this.mobileMenuItems.forEach(item => {
			const sublist = item.querySelector(".mobile-menu__sublist");
			if (sublist && item.classList.contains("mobile-menu__item--active")) {
				item.classList.remove("mobile-menu__item--active");
				$(sublist).slideUp();
			}
		});
	}
}

export default Header
