class Accordion {
	constructor(block) {
		this.block = block;
		this.header = block.querySelector('.faqs__accordion-item-header');
		this.content = block.querySelector('.faqs__accordion-item-content');
		this.icon = this.header.querySelector('.faqs__accordion-item-header-icon');

		this.init();
	}

	init() {
		this.header.addEventListener('click', () => {
			this.toggleAccordion();
		});
	}

	toggleAccordion() {
		if (this.content.classList.contains('active')) {
			this.closeAccordion();
		} else {
			this.openAccordion();
		}
	}

	openAccordion() {
		this.content.classList.add('active');
		this.icon.classList.add('active');

		// Set max-height to scrollHeight to allow for smooth transition
		this.content.style.maxHeight = this.content.scrollHeight + 'px';
	}

	closeAccordion() {
		this.content.classList.remove('active');
		this.icon.classList.remove('active');

		// Set max-height to 0 to allow for smooth transition back
		this.content.style.maxHeight = 0;
	}
}

export default Accordion;
