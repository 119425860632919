import axios from "axios"

class ArchiveProduct {
	constructor(page) {
		this.page = page
		this.sortSelect = page.querySelector(".wc-archive-product__sort-select")
		this.sortFakeSelect = page.querySelector(".wc-archive-product__sort-fake-select")
		this.sortFakeSelectOptions = page.querySelectorAll(".wc-archive-product__sort-fake-select-option")
		this.sortFakeSelectValue = page.querySelector(".wc-archive-product__sort-fake-select-value")
		this.loadMore = page.querySelector(".wc-archive-product__load-more")
		this.products = page.querySelectorAll(".wc-archive-product__item")
		this.productsContainer = page.querySelector(".wc-archive-product__grid")
		this.events()
	}

	events() {
		this.sortFakeSelect.addEventListener("click", () => this.toggleFakeSelectOptions())
		document.addEventListener("click", e => this.closeFakeSelectOptions(e))

		this.sortFakeSelectOptions.forEach(option => {
			option.addEventListener("click", e => this.selectOption(e, option))
		})

		this.loadMore.addEventListener("click", e => this.loadMoreProducts(e))
	}

	toggleFakeSelectOptions() {
		this.sortFakeSelect.classList.toggle("wc-archive-product__sort-fake-select--active")
	}

	closeFakeSelectOptions(e) {
		if (!e.target.closest(".wc-archive-product__sort-fake-select")) {
			this.sortFakeSelect.classList.remove("wc-archive-product__sort-fake-select--active")
		}
	}

	selectOption(e, option) {
		if (this.sortFakeSelectValue.textContent != option.textContent) {
			this.sortFakeSelectValue.textContent = option.textContent
			this.sortSelect.value = option.dataset.value
			this.loadMoreProducts(e, true)
		}
	}

	startLoading() {
		this.loadMoreText = this.loadMore.textContent
		this.loadMore.setAttribute("disabled", "disabled")
		this.loadMore.innerHTML = `<i class="fa-solid fa-spinner"></i>`
	}

	stopLoading() {
		this.loadMore.textContent = this.loadMoreText
		this.loadMore.removeAttribute("disabled")
	}

	async loadMoreProducts(e, replaceItems = false) {
		e.preventDefault()
		this.startLoading()
		const alreadyLoaded = replaceItems ? [] : Array.from(this.products).map(product => parseInt(product.dataset.id))

		if (replaceItems) {
			this.productsContainer.innerHTML = '<div class="wc-archive-product__loading"><i class="fa-solid fa-spinner"></i></div>'
			this.loadMore.classList.add("wc-archive-product__load-more--hidden")
		}

		try {
			const response = await axios({
				url: `/wp-json/betafit/v1/loadMoreProducts`,
				method: "get",
				params: {
					alreadyLoaded,
					count: parseInt(this.page.dataset.count),
					category: this.page.dataset.category,
					sort: this.sortSelect.value,
				},
			})

			console.log(response.data)

			if (response.data.success) {
				let productHTML = ""
				response.data.products.forEach(product => {
					productHTML += `
					<a class="wc-archive-product__item" href="${product.url}" data-id="${product.id}">
						<img class="wc-archive-product__item-image" src="${product.image}" alt="">
						<h5 class="wc-archive-product__item-name">${product.title}</h5>
						${product.sku ? `<span class="wc-archive-product__item-code">Product code: ${product.sku}</span>` : ""}
						
						${
							product.price || product.quantity
								? `<div class="wc-archive-product__item-pricebox">
								${product.price ? `<span class="wc-archive-product__item-price">${product.price}</span> <span class="wc-archive-product__item-vat"></span>` : ""}
							</div>`
								: ""
						}
						
						<span class="wc-archive-product__item-view button button--green">View</span>
					</a>
          `
				})

				if (replaceItems) {
					this.productsContainer.innerHTML = productHTML
				} else {
					this.productsContainer.insertAdjacentHTML("beforeend", productHTML)
				}
				this.products = this.page.querySelectorAll(".wc-archive-product__item")

				if (!response.data.moreToLoad) {
					this.loadMore.classList.add("wc-archive-product__load-more--hidden")
					this.stopLoading()
				} else {
					this.loadMore.classList.remove("wc-archive-product__load-more--hidden")
					this.stopLoading()
				}
			} else {
				throw new Error(response.data.message)
			}
		} catch (error) {
			console.log(error.message)
			this.stopLoading()
		}
	}
}

export default ArchiveProduct
